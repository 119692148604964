// The highest possible Connectivity Meter reading
export const MAX_CONNECTIVITY = 100;

// Connectivity Meter's default reading when the user first starts the app
export const DEFAULT_CONNECTIVITY = 50;

// The rate at which the connectivity goes down (how many minutes does it take to go from 100% to 0%)
export const CONNECTIVITY_DEPLETION_RATE = 10;

// How many seconds you have to wait to scan the same person again
export const SECONDS_TO_WAIT_BETWEEN_SCANS = 60;

// The chance (% out of a hundred) that they are unable to hang out right now
export const CHANCE_UNABLE_TO_HANG_OUT = 25;

// How often to check the video stream for a QR code (in milliseconds)
export const INTERVAL_LOOK_FOR_CODE = 100;

// How often to update the meter with the latest connectivty reading (in milliseconds)
export const INTERVAL_UPDATE_METER = 100;
