import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { INTERVAL_UPDATE_METER } from './constants';
import { mapPercentageToArray } from './util';
import { getConnectivity, setConnectivity, resetConnectivity } from './connectivityCalculator';
import connectivityStrings from './connectivityStrings';
import ConnectivityGauge from './ConnectivityGauge';
import './css/MeterScreen.css';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {connectivity: getConnectivity()};
    this.intervalId = window.setInterval(this.updateConnectivityMessage, INTERVAL_UPDATE_METER);
  }

  componentWillMount() {
    this.updateConnectivityMessage();
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId);
  }

  updateConnectivityMessage = () => {
    const connectivity = getConnectivity();
    const connectivityString = mapPercentageToArray(connectivity, connectivityStrings);
    const meterGradeColor = mapPercentageToArray(connectivity, ['red', 'yellow', 'green']);

    this.setState({connectivityString, meterGradeColor});
  }

  clickResetButton() {
    resetConnectivity();
  }

  updateConnectivity(event) {
    setConnectivity(event.target.value);
  }

  render() {
    const { meterGradeColor, connectivityString } = this.state;

    return (
      <div className="MeterScreen">
        <header>
          <h1>Connectivity Meter</h1>
          <p className="tagline">A game about connecting with humans</p>
        </header>

        <ConnectivityGauge showEmoji showNumber />
        <div className="connectivity-grade">
          <span className={`connectivity-grade-text grade-color-${meterGradeColor}`}>
            {connectivityString}
          </span>
        </div>

        {this.props.match.params.debug === 'debug' &&
          <React.Fragment>
            <input type="range" min="0" max="100" onChange={this.updateConnectivity} />
            <button onClick={this.clickResetButton}>Reset</button>
          </React.Fragment>
        }

        <div className="instructions">
          <ul>
            <li>The Connectivity Meter (above) indicates how "connected" you feel to others, socially</li>
            <li>It slowly depletes over time</li>
            <li>The meter increases when you hang out with people (represented by <Link to="/scan">scanning</Link> their QR code)</li>
            <li>Show them <Link to="/code"><em>your</em> QR code</Link> so they can increase their Connectivity Meter as well</li>
          </ul>
        </div>
      </div>
    );
  }
}
