import React, { Component } from 'react';
import QRious from 'qrious';

export default class QrCode extends Component {
  static defaultProps = {
    value: '',
    size: 100,
    padding: null,
    background: 'white',
    backgroundAlpha: 1,
    foreground: 'black',
    foregroundAlpha: 1,
    level: 'L',
    mime: 'image/png',
  };

componentDidUpdate() {
    new QRious({
      element: this.refs.qrcode,
      value: this.props.value,
      size: this.props.size,
      padding: this.props.padding,
      background: this.props.background,
      backgroundAlpha: this.props.backgroundAlpha,
      foreground: this.props.foreground,
      foregroundAlpha: this.props.foregroundAlpha,
      level: this.props.level,
      mime: this.props.mime,
    });
  }

  render() {
    return (
      <canvas className="QrCode" ref="qrcode" alt={`QR code for ${this.props.value}`} />
    );
  }
}
