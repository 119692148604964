import React, { Component } from 'react';
import jsQR from 'jsqr';
import Viewfinder from './Viewfinder';
import { INTERVAL_LOOK_FOR_CODE } from './constants';

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canvasEl: document.createElement('canvas'),
      lookForCodeIntervalId: null,
    };
  }

  componentWillUnmount() {
    window.clearInterval(this.state.lookForCodeIntervalId);
  }

  // Check the video stream for a QR code using `jsQR`
  lookForCode = (video) => {
    const { canvasEl } = this.state;
    const context = canvasEl.getContext('2d');

    context.drawImage(video, 0, 0, canvasEl.width, canvasEl.height);
    const imageData = context.getImageData(0, 0, canvasEl.width, canvasEl.height);

    const qrCode = jsQR(imageData.data, imageData.width, imageData.height);

    if (qrCode) {
      this.props.onFoundCode(qrCode);
    }
  }

  onPlay = (video) => {
    const lookForCodeIntervalId = window.setInterval(
      this.lookForCode.bind(this, video),
      INTERVAL_LOOK_FOR_CODE
    );

    // Save this for later so we can clear it when the component unmounts
    this.setState({lookForCodeIntervalId});
  }

  render() {
    return (
      <Viewfinder onPlay={this.onPlay} />
    );
  }
}
