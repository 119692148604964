import React, { Component } from 'react';
import { HashRouter as Router, Route, NavLink } from 'react-router-dom';
import MeterScreen from './MeterScreen';
import ScannerScreen from './ScannerScreen';
import FriendCodeScreen from './FriendCodeScreen';
import FriendCode from './FriendCode';
import ConnectivityGauge from './ConnectivityGauge';
import HandsIcon from './HandsIcon';
import './css/App.css';

export default class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route exact path="/:debug(debug)?" component={MeterScreen} />
          <Route exact path="/scan" component={ScannerScreen} />
          <Route exact path="/code" component={FriendCodeScreen} />

          <nav className="navbar">
            <ul>
              <li><NavLink exact activeClassName="active" to="/"><ConnectivityGauge showEmoji /></NavLink></li>
              <li><NavLink exact activeClassName="active" to="/scan"><HandsIcon /></NavLink></li>
              <li><NavLink exact activeClassName="active" to="/code"><FriendCode /></NavLink></li>
            </ul>
          </nav>
        </div>
      </Router>
    );
  }
}
