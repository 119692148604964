import React, { Component } from 'react';
import { sample } from 'lodash';
import { SECONDS_TO_WAIT_BETWEEN_SCANS } from './constants';
import ScannerContext from './ScannerContext';
import { invalid, tooRecent } from './reasons';
import { getStatus } from './status';
import { increaseConnectivity } from './connectivityCalculator';
import QrReader from './QrReader';

export default class extends Component {
  static contextType = ScannerContext;

  state = {
    qrReaderStarted: !!window.localStorage.qrReaderStarted,
    permission: null,
    setPermission: (permission) => {
      this.setState({permission});
      if (permission === 'granted') {
        window.localStorage.qrReaderStarted = 'true';
      }
    },
  };

  componentWillMount() {
    if (!this.state.qrReaderStarted) {
      this.setState({permission: 'prompt'});
    }
  }

  startQrReader = () => {
    this.setState({qrReaderStarted: true});
  }

  onFoundCode = (code) => {
    const scannedCode = code.data;

    // Check if user scanned a barcode with the website's URL on it
    // (They're not supposed to do this, but someone probably will)
    if (scannedCode === 'https://ConnectivityMeter.com') {
      return window.alert(`You admire the magnificent and tastefully-designed poster, but it doesn't help you feel any more connected to other people.`);
    }

    // Check if barcode is a valid Connectivity Meter user
    if (!this.isValidFriendCode(scannedCode)) {
      return window.alert(
        sample(invalid)
      );
    }

    // Stripe out the `CM:` prefix to get the *actual* friend code
    const friendCode = scannedCode.substring(3);
    const lastScannedThisCode = window.localStorage.getItem(`friend:${friendCode}`);
    const timeSinceLastScanned = Date.now() - lastScannedThisCode;

    // Check if the user has been scanned recently
    if (timeSinceLastScanned < SECONDS_TO_WAIT_BETWEEN_SCANS * 1000) {
      return window.alert(
        sample(tooRecent)
      );
    }

    // Check if the current user is able to hang out right now
    const currentUserStatus = getStatus();
    if (currentUserStatus) {
      return window.alert(currentUserStatus);
    }

    // Check if the scanned user is able to hang out right now
    const scannedUserStatus = getStatus(friendCode, 'them');
    if (scannedUserStatus) {
      return window.alert(scannedUserStatus);
    }

    // Otherwise, scan that user!
    return this.addFriend(friendCode);
  }

  isValidFriendCode(code) {
    return code.substring(0, 3) === 'CM:';
  }

  addFriend(code) {
    const pointsToAdd = 10;
    increaseConnectivity(pointsToAdd);

    window.localStorage.setItem(`friend:${code}`, Date.now());
    window.alert(`Your connectivity increased by ${pointsToAdd} points!`);
  }

  promptText = () => (
    <React.Fragment>
      <h1>Just one quick thing</h1>
      <p>Connectivity Meter uses your camera to scan QR codes. You'll need to grant access to your camera to make this work.</p>
      <p><button onClick={this.startQrReader}>Continue</button></p>
    </React.Fragment>
  )

  grantedText = () => (
    <p>Increase your Connectivity Meter by finding a friend to hang out with and scanning their QR code.</p>
  )

  deniedText = () => (
    <React.Fragment>
      <h1>Uh oh :(</h1>
      <p>Connectivity Meter needs access to a camera to work. Please enable camera access in your browser settings and refresh this page to continue.</p>
    </React.Fragment>
  )

  render() {
    const { qrReaderStarted, permission } = this.state;
    return (
      <ScannerContext.Provider value={this.state}>
        {qrReaderStarted && <QrReader onFoundCode={this.onFoundCode} />}

        <div className={`text-wrapper permission-${permission}`}>
          <div className="text">
            {permission === 'prompt' && this.promptText()}
            {permission === 'granted' && this.grantedText()}
            {permission === 'denied' && this.deniedText()}
          </div>
        </div>
      </ScannerContext.Provider>
    );
  }
}
