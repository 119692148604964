import React, { Component } from 'react';
import ScannerContext from './ScannerContext';
import Reticle from './Reticle';
import './css/Viewfinder.css';

export default class extends Component {
  static contextType = ScannerContext;
  state = {hasStream: false};

  componentDidMount() {
    this.getStream();
    document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
  }

  componentWillUnmount() {
    this.stopStream();
    document.removeEventListener('visibilitychange', this.handleVisibilityChange, false);
  }

  stopStream() {
    if (this.stream) {
      this.stream.getTracks()[0].stop();
    }
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      this.stopStream();
    } else {
      this.getStream();
    }
  }

  getStream(deviceIndex) {
    navigator.mediaDevices.getUserMedia({audio: false, video: {facingMode: 'environment'}})
      .then((stream) => { this.handleStream(stream); })
      .catch((error) => {
        this.context.setPermission('denied');
      });
  };

  handleStream = (stream) => {
    this.context.setPermission('granted');
    this.stream = stream;
    const { video } = this.refs;

    // Necessary for mobile Safari for some reason
    // https://github.com/webrtc/samples/issues/929
    video.setAttribute('playsinline', '');

    video.srcObject = stream;

    video.addEventListener('loadedmetadata', video.play, false);
    video.addEventListener('play', this.props.onPlay.bind(this, video), false);

    this.setState({hasStream: true});
  }

  render() {
    return (
      <div className="Viewfinder">
        <video ref="video"></video>
        {this.state.hasStream && <Reticle />}
      </div>
    );
  }
}
