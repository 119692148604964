import React, { Component } from 'react';
import { getUserId } from './friendCodeHelpers';
import QrCode from './QrCode';
import './css/FriendCode.css';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {userId: ''};
  }

  componentDidMount() {
    this.setState(state => ({
      userId: getUserId(),
    }));
  }

  render() {
    return (
      <div className="FriendCode">
        <QrCode value={`CM:${this.state.userId}`} size={119} level="H" />
      </div>
    );
  }
}
