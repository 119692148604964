export default [
  // Red
  '😭',
  '🥺',
  '😢',
  '😥',
  '😣',
  '😞',
  '😔',

  // Yellow
  '🙁',
  '😟',
  '😬',
  '😐',
  '😗',
  '🙂',
  '😌',

  // Green
  '😎',
  '😊',
  '😄',
  '😁',
  '🥳',
  '🥰',
  '😍',
];
