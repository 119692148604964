export function translateRange(value, leftMin, leftMax, rightMin, rightMax) {
    // Figure out how 'wide' each range is
    const leftSpan = leftMax - leftMin;
    const rightSpan = rightMax - rightMin;

    // Convert the left range into a 0-1 range (float)
    const valueScaled = (value - leftMin) / leftSpan;

    // Convert the 0-1 range into a value in the right range.
    return rightMin + (valueScaled * rightSpan);
}

export function mapPercentageToArray(percentage, array) {
  const index = Math.floor(
    translateRange(percentage, 0, 101, 0, array.length)
  );

  return array[index];
}
