import { random } from 'lodash';

// Get the user ID stored in localStorage
// If no ID is found, generate one and save it to localStorage
export function getUserId() {
  let userId = window.localStorage.getItem('userId');

  if (!userId) {
    userId = generateUserId();
    setUserId(userId);
  }

  return userId;
}

export function generateUserId() {
  // Random integer between 1 and 1,000,000 (inclusive)
  const integer = random(1, 1000000);

  // Convert the integer to a base 36 string
  return integer.toString(36);
}

export function setUserId(userId) {
  return window.localStorage.setItem('userId', userId);
}
