import { hash } from 'spark-md5';
import { CHANCE_UNABLE_TO_HANG_OUT } from './constants';
import { randomReasons } from './reasons';
import { translateRange } from './util';
import { getUserId } from './friendCodeHelpers';

export function getStatus(userID, whichUser = 'you') {
  userID = userID || getUserId();
  const minute = new Date().getMinutes();
  const theHash = hash(`${minute} ${userID}`);

  // Get the last four digits (as base 10) of the hex string
  const hashInt = (parseInt(theHash, 16) % 10000).toString();

  // Split up this four digit number into two numbers that will each range from 00 to 99
  const firstSortaRandom = hashInt.substr(0, 2);
  const secondSortaRandom = hashInt.substr(2, 2);

  if (firstSortaRandom <= CHANCE_UNABLE_TO_HANG_OUT) {
    // This shouldn't be random
    // It needs to map from the sortaRandom (00-99) to one of the statuses
    // Probably use something like mapPercentageToArray from util
    return getStatusStringFromInt(secondSortaRandom)[whichUser];
  }

  // A status tring is a reason they can't hang out
  // False means there's no reason they can't hang out
  return false;
}

// value - An integer between 0 and 99
// Returns an element from randomReasons based on that value
// For example, 0 will return the first, 99 will return the last,
// and everything in between will be scaled accordingly
function getStatusStringFromInt(value) {
  const index = Math.round(
    translateRange(value, 0, 99, 0, randomReasons.length - 1)
  );

  return randomReasons[index];
}
