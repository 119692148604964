import React from 'react';

const Reticle = () => (
  <svg className="Reticle" viewBox="-50 -50 200 200">
    <path d="M25,2 L2,2 L2,25" fill="none" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2,75 L2,98 L25,98" fill="none" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M75,98 L98,98 L98,75" fill="none" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M98,25 L98,2 L75,2" fill="none" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Reticle;
