import React, { Component } from 'react';
import { INTERVAL_UPDATE_METER } from './constants';
import { mapPercentageToArray } from './util';
import emojis from './emojis';
import { initializeStorageIfEmpty, getConnectivity } from './connectivityCalculator';
import Gauge from './Gauge';

export default class extends Component {
  constructor(props) {
    super(props);

    const connectivity = getConnectivity();
    const emoji = this.getEmoji(connectivity);
    this.state = {connectivity, emoji};

    initializeStorageIfEmpty();

    this.intervalId = window.setInterval(this.updateMeterReading, INTERVAL_UPDATE_METER);
  }

  getEmoji = (connectivity) => {
    if (!this.props.showEmoji) {
      return '';
    }

    return mapPercentageToArray(connectivity, emojis);
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId);
  }

  updateMeterReading = () => {
    const connectivity = getConnectivity();
    const emoji = this.getEmoji(connectivity);

    this.setState({connectivity, emoji});
  }

  render() {
    const { connectivity, emoji } = this.state;
    const { showNumber } = this.props;
    return (
      <div className="ConnectivityGauge">
        <Gauge percentage={connectivity} emoji={emoji} showNumber={showNumber} />
      </div>
    );
  }
}
