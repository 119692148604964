export const invalid = [
  "That doesn't appear to be another Connectivity Meter user.",
];

export const tooRecent = [
  "You just hung out with this person not too long ago. You could both use a break. Why not find someone else in the meantime?",
];

export const randomReasons = [
  {
    you: "Unfortunately, you're not really feeling up to hanging out right now. Maybe try again a little later.",
    them: "Unfortunately, they're not really feeling up to hanging out right now. Maybe try again a little later.",
  },
  {
    you: "Your social anxiety is currently preventing you from seeing people right now. Try again later.",
    them: "Their social anxiety is currently preventing them from seeing people right now. Try again later.",
  },
  {
    you: "You want to *want* to hang out, but your depression is making it impossible. You let their calls go to voicemail because you don't want to say no.",
    them: "You reach out to them, but they're not answering their phone.",
  },
  {
    you: "You just don't have the emotional energy to hang out right now.",
    them: "You ask them to hang out, but they say they're too tired.",
  },
  {
    you: `Things haven't been going so hot lately and you'd rather not have to rehash it as you catch up.`,
    them: `You ask them if they want to catch up, but they said they're too busy.`,
  },
  {
    you: `You don't feel like the best version of yourself right now and you don't want anyone to see you like this.`,
    them: `You ask them to hang, but they said they'd rather stay in.`,
  },
  {
    you: `You're not convinced that this person *really* wants to be your friend. You've decided that they're probably just being nice.`,
    them: `They're not being particularly receptive to your offers to hang out for some reason.`,
  },
  {
    you: `Your significant other doesn't like you hanging out without them, so you don't.`,
    them: `They said they have plans with their significant other tonight.`,
  },
  {
    you: `They're going to a bar with some friends, but you've had some bad experiences and don't want to be pressured to drink.`,
    them: `You invite them to hang out with your friends, but they say that they can't make it.`,
  },
  {
    you: `You had a long day at work and are feeling too drained to hang out.`,
    them: `This person is not feeling up to hanging out.`,
  },
  {
    you: `You have to stay home to take care of your kid and it's too late to find a sitter, so hanging out isn't an option right now.`,
    them: `They have to stay home to take care of their kid so they can't hang out right now.`,
  },
  {
    you: `You haven't been getting enough sleep lately, so you decide to stay home tonight instead.`,
    them: `This person is unable to hang out right now.`,
  },
];
