import React, { Component } from 'react';
import FriendCode from './FriendCode';
import './css/FriendCodeScreen.css';

export default class extends Component {
  render() {
    return (
      <div className="FriendCodeScreen">
        <FriendCode />
        <div className="instructions">
          <p>Show this QR code to your friend when you hang out to increase their Connectivity Meter.</p>
        </div>
      </div>
    );
  }
}
