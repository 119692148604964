export default [
  // Red
  "You're suffering from extreme isolation",
  'You feel very lonely',
  'You feel disconnected from people around you',

  // Yellow
  "You're starting to feel somewhat disconnected",
  "You're doing alright, socially",
  'You feel content with your social life',

  // Green
  "You're very satisfied with your friendships",
  'You have lots of meaningful relationships',
  'You feel extremely connected with loved ones',
];
