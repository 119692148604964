import { MAX_CONNECTIVITY, DEFAULT_CONNECTIVITY, CONNECTIVITY_DEPLETION_RATE } from './constants';

export function initializeStorageIfEmpty(connectivity) {
  if (!Number.isInteger(getLastKnownConnectivity()) || !Number.isInteger(getLastKnownTime())) {
    resetConnectivity();
  }
}

export function getConnectivity() {
  let lastKnownConnectivity = getLastKnownConnectivity();

  if(!Number.isInteger(lastKnownConnectivity)) {
    lastKnownConnectivity = DEFAULT_CONNECTIVITY;
  }

  const lastKnownTime = getLastKnownTime() || Date.now();
  const connectivity = calculateConnectivity(lastKnownConnectivity, lastKnownTime);
  return Math.max(0, connectivity);
}

export function resetConnectivity() {
  setConnectivity(DEFAULT_CONNECTIVITY);
}

export function setConnectivity(connectivity) {
  window.localStorage.setItem('lastKnownConnectivity', connectivity);
  window.localStorage.setItem('lastKnownTime', Date.now());
}

export function increaseConnectivity(amountToAdd) {
  const current = getConnectivity();
  const newConnectivity = Math.min(current + amountToAdd, MAX_CONNECTIVITY);
  return setConnectivity(newConnectivity);
}

function getLocalStorageAsInt(key) {
  return parseInt(window.localStorage.getItem(key));
}

function getLastKnownConnectivity() {
  return getLocalStorageAsInt('lastKnownConnectivity');
}

function getLastKnownTime() {
  return getLocalStorageAsInt('lastKnownTime');
}

function calculateConnectivity(lastKnownConnectivity, lastKnownTime) {
  // `timeSinceLastCalculation` is in milliseconds
  const timeSinceLastCalculation = Date.now() - lastKnownTime;

  // `CONNECTIVITY_DEPLETION_RATE` is how many minutes it should take to go from 100% to 0%
  const amountConnectivityWentDown = timeSinceLastCalculation / (CONNECTIVITY_DEPLETION_RATE * 600);

  return lastKnownConnectivity - amountConnectivityWentDown;
}
